import { HttpClient, HttpEventType } from '@angular/common/http';
import { effect, Injectable, signal, inject } from '@angular/core';
import { catchError, from, map, mergeMap, Observable, Subject, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { CountryCode } from 'libphonenumber-js';
import { StateStoreService } from 'src/services/state-store/state-store.service';
import { IPicture, User } from '@appines/appines_types';
import { CustomUploadComponent } from 'src/components/custom-upload/custom-upload.component';
import { ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends StateStoreService<User> {
  private http = inject(HttpClient);
  private authenticationService = inject(AuthenticationService);
  private modalController = inject(ModalController);

  constructor() {
    super();

    // if hasAccessToken change, get new user data
    effect(() => {
      if (this.authenticationService.hasAccessToken.asReadonly()) {
        this.authenticationService.checkAuthentication().then(() => {
          this.setProfileInState().subscribe();
        });
      }
    });
  }

  setProfileInState() {
    const url = environment.apiUrl + '/users/myProfile';

    return this.http.get<User>(url).pipe(
      map((data) => {
        this.setState(data);
        return data;
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<{ accessToken: string; refreshToken: string }> {
    const url = environment.apiUrl + '/users/change-password';

    return this.http.put<{ accessToken: string; refreshToken: string }>(url, { oldPassword, newPassword }).pipe(
      mergeMap(async ({ accessToken, refreshToken }) => {
        await this.authenticationService.setAccessToken(accessToken);
        await this.authenticationService.setRefreshToken(refreshToken);
        return { accessToken, refreshToken };
      })
    );
  }

  changeEmail(newMail: string) {
    const url = environment.apiUrl + '/users/change-mail';

    return this.http.post(url, { newMail });
  }

  confirmChangeEmail(token: string) {
    const url = environment.apiUrl + '/users/change-mail/validate';
    return this.http.post<string>(url, { token }).pipe(
      mergeMap(async (email) => {
        this.set('email', email);
        return email;
      })
    );
  }

  changeUserInfos(userId: string, firstName: string, lastName: string, phone: string, region: CountryCode) {
    const url = environment.apiUrl + `/users/${userId}`;

    return this.http.put<User>(url, { firstName, lastName, phone, region });
  }

  uploadProfilePicture(data: string): Observable<IPicture> {
    const url = environment.apiUrl + `/users/upload-picture`;

    const response = new Subject<any>();
    const progress = signal<number>(0);

    const upload$ = from(
      this.modalController.create({
        component: CustomUploadComponent,
        componentProps: { progress },
        cssClass: ['dynamic-height-modal', 'no-scroll-dynamic-height-modal'],
        backdropDismiss: false
      })
    ).pipe(
      switchMap((loadingModal) => {
        loadingModal.present();
        return this.http.post<IPicture>(url, { data }, { reportProgress: true, observe: 'events' }).pipe(
          tap((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  progress.set(Math.round((100 * event.loaded) / event.total));
                }
                break;
              case HttpEventType.Response:
                loadingModal.dismiss();
                response.next(event.body);
                break;
            }
          }),
          catchError((error) => {
            loadingModal.dismiss();
            console.error('An error appear during upload', error);
            return throwError(() => error);
          })
        );
      })
    );

    return upload$.pipe(
      switchMap(() =>
        response.pipe(
          map((res) => {
            this.set('picture', res);
            return res;
          })
        )
      )
    );
  }

  deleteProfilePicture() {
    const url = environment.apiUrl + `/users/delete-picture`;
    return this.http.delete<IPicture>(url);
  }
}
